import {useEffect} from "react";
import ReactGA from "react-ga4";
const Anylatics = () => {
  useEffect(() => {
    ReactGA.initialize("G-HX89WZZR31");
  }, []);
  return null;
};

export default Anylatics;
