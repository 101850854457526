
import logo from "../Assests/logo.svg";
import "../Components/logo.css";

function Logo() {
  return (
    <div className="logo-container">
      <img src={logo} alt="mainlogo" />
    </div>
  );
}

export default Logo;