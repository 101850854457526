const ReviewData = [
    {
        "id": 1,
        "name" : "Chris James Murphy",
        "Designation":  "Founder, Klink Finance",
        "image" :  require("../../Assests/chris_m.jpg"),
        "review": "\"We're very happy and look forward to continuing our engagement with their team.\"",
        "reviewLogo" : "../../Assests/clutch_logo.png",
        "reviewdate":"Jul 14, 2023",
        "location": "London, United Kingdom",
        "path": "https://clutch.co/profile/blocsys-technologies?utm_campaign=widget&utm_content=logo&utm_medium=2&utm_source=widget&utm_term=blocsys.com#review-2192540"


    },
    {
        "id": 2,
        "name" : "Tyler Stafford",
        "Designation": "CEO, Panacea Financial",
        "image" : require("../../Assests/tyler.jpg"),
        "review": "\"We have been very happy with the partnership.\"",
        "reviewLogo" : "../../Assests/clutch_logo.png",
        "reviewdate":"Jan 28, 2024",
        "location": "Little Rock, Arkansas",
        "path":"https://clutch.co/profile/blocsys-technologies?utm_campaign=widget&utm_content=logo&utm_medium=2&utm_source=widget&utm_term=blocsys.com#review-2322615"



    },
    {
        "id": 3,
        "name" : "Jake Schmitt",
        "Designation" : "CTO, Spreetail",
        "image" : require("../../Assests/jake.jpg"),
        "review": "\"They were always on time and committed to the deadline established for the project.\"",
        "reviewLogo" : "../../Assests/clutch_logo.png",
        "reviewdate":"Jan 21, 2024",
        "location" : "Lincoln, Nebraska",
        "path": "https://clutch.co/profile/blocsys-technologies?utm_campaign=widget&utm_content=logo&utm_medium=2&utm_source=widget&utm_term=blocsys.com#review-2317367"


    }
]
export default ReviewData;
