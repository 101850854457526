import React, { useEffect, useState } from 'react';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import Ellipsis from '../../Assests/Ellipsis-1s-200px.gif'
import BlockCalImg from '../../Assests/Block_cal_img.svg'
import './blockCal.css'
import { Helmet } from 'react-helmet';
import Aos from "aos";
import Logo from "../logo";

function BlockCalculator() {
  const BaseUrl = `${process.env.REACT_APP_BASE_URL}/Blockgenerator/latestBlock`;
  const [data, setData] = useState({
    network: "",
    startpoint: "",
    endpoint: "",
  });
  const [newBlockData, SetnewBlockData] = useState({});
  const [reset, setReset] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [netWork, setNetwork] = useState("");
  const [newWorkUrl, setNetWorkUrl] = useState("");

  function submit(e) {
    e.preventDefault();
    setData("");
    setLoading(true);

    axios
      .post(BaseUrl, {
        network: netWork,
        startpoint: data.startBlock,
        endpoint: data.endBlock,
      })
      .then((res) => {
        const blockData = { ...res.data.data };
        SetnewBlockData(blockData);
        setLoading(false);
      });
  }

  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  const [networkOptions] = useState([
    { name: "BSC-Mainnet", value: "bscMainnet" },
    { name: "BSC-Testnet", value: "bscTestnet" },
    { name: "ETH-Mainnet", value: "ethereumMainnet" },
  ]);

  const handleNetworkChange = async (e) => {
    const newNetworkValue = e.target.value;
    NewNetWorkUrl(newNetworkValue);
    await setNetwork(newNetworkValue);
  };

  const NewNetWorkUrl = (newnetwork) => {
    if (newnetwork === "bscMainnet") {
      setNetWorkUrl("https://bscscan.com/block/countdown/");
    } else if (newnetwork === "bscTestnet") {
      setNetWorkUrl("https://testnet.bscscan.com/block/countdown/");
    } else if (newnetwork === "ethereumMainnet") {
      setNetWorkUrl("https://etherscan.io/block/countdown/");
    } else if (newnetwork === "ethereumTestnet") {
      setNetWorkUrl("https://goerli.etherscan.io/block/countdown/");
    }
  };

  const onCopyText = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const resetData = () => {
    setReset(true);
    setNetwork("");
    setData({
      startpoint: "",
      endpoint: "",
    });
    SetnewBlockData({
      startBlock: "",
      endBlock: "",
    });

    setTimeout(() => {
      setReset(false);
    }, 1000);
  };


  useEffect(() => {
    Aos.init({duration: 500});
  }, []);
  
  return (
    <div>
      <Logo />
       <Helmet>
      <title>Block Calculator - Blocsys</title>
      <meta name="description" content="Blocsys Block Calculator: Your precision tool for efficient blockchain calculations. Explore with confidence. #Blocsys #Blockchain" />
      <meta name="keywords" content="Block Calculation, blockchain, blocsys, blocsys-blogs, Web3 Tools, Calculate Blocks,Blocsys Tool's" />
      <meta name="tool's description" content="blocsys tool's" />
      <link rel="icon" href="/favicon.ico" />
      <meta property="og:title" content="Tools - Blocsys" />
      <meta property="og:description" content="Calculate Your Blocks" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://blocsys.com/web3-block-calculator" />
      <meta
        property="og:image"
        content="https://media.licdn.com/dms/image/C4D0BAQGuijH3NOSAVw/company-logo_200_200/0/1656508703897?e=2147483647&v=beta&t=UNKFrOWMEILFnHnPu0sXQCuJvcaDnIZWzNKI-3NkEuM"
      />
      <meta property="og:image:alt" content="Tools - Blocsys" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://blocsys.com/web3-block-calculator" />
      </Helmet>
  <section className="blockcal" data-aos="fade-up">
        <div data-aos="fade-up">
          <h1>Calculate Your Blocks</h1>
          <form onSubmit={submit} >
            <div className="from_Box">
              <h2 style={{fontFamily : "Inter"}}>Network</h2>
              <div className="network_box">
                <div>
                  <FormControl
                    required
                    className="network-formcontrol"
                    style={{ width: "100px" , cursor: 'pointer'}}
                  >
                    <InputLabel id="select-required-label">Network</InputLabel>
                    <Select
                      style={{marginRight: "50px" }}
                      id="network"
                      value={netWork}
                      className="custom-select-width"
                      options={networkOptions}
                      label="Select"
                      onChange={handleNetworkChange}
                    >
                      <MenuItem disabled> {/* <em>None</em> */}</MenuItem>
                      {networkOptions.map(({ name, value }) => (
                        <MenuItem value={value} key={value}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <br />
                <br />
                <div data-aos="fade-up">
                  <h5>* The block calculation is based on last 24 hrs</h5>
                </div>
              </div>
              <div data-aos="fade-up">
                <h3>Please enter time in GMT only</h3>
              </div>
              <div className="form_Box_card">
                <div data-aos="fade-up">
                  <h5>Start Date</h5>
                  <input
                    type="datetime-local"
                    onChange={(e) => handle(e)}
                    value={data.startBlock}
                    id="startBlock"
                    label="START TIME"
                    placeholder="Start Date"
                  />
                </div>
                <div data-aos="fade-up">
                  <h5>End Date</h5>
                  <input
                    type="datetime-local"
                    onChange={(e) => handle(e)}
                    value={data.endBlock}
                    id="endBlock"
                    label="END TIME"
                    placeholder="End Date"
                  />
                </div>
                <div data-aos="fade-up">
                  <button type="submit">SUBMIT</button>
                </div>
              </div>
            </div>
          </form>
          <div className="form_Box_Display">
            <div >
              <h2>Start Block</h2>
              <div className="block_result_box" >
                {!isLoading && (
                  <a
                    target="_blank"
                    // href={`https://testnet.bscscan.com/block/countdown/${newBlockData.startBlock}`}
                    href={`${newWorkUrl}${newBlockData.startBlock}`}
                    title="Please click to check your block"
                    rel="noreferrer"
                  >
                    {newBlockData.startBlock}
                  </a>
                )}
                {isLoading && (
                  <img src={Ellipsis} alt="Ellipsis" height={32} />
                )}
              </div>
            </div>

            <div >
              <h2>End Block</h2>
              <div className="block_result_box" >
                {!isLoading && (
                  <a
                    target="_blank"
                    // href={`https://testnet.bscscan.com/block/countdown/${newBlockData.endBlock}`}
                    href={`${newWorkUrl}${newBlockData.endBlock}`}
                    title="Please click to check your block"
                    rel='noreferrer'
                  >
                    {newBlockData.endBlock}
                  </a>
                )}
                {isLoading && (
                  <img src={Ellipsis} alt="Ellipsis" height={32} />
                )}
              </div>
            </div>

            <div className="copy_reset_container" >
              <div className="copy-icon-box" >
                {" "}
                <br /> <br />
                <button>
                  <span>
                    <CopyToClipboard
                      text={`Your start block is: ${newBlockData.startBlock} , Your end block is: ${newBlockData.endBlock}`}
                      onCopy={onCopyText}
                    >
                      <ContentCopyIcon />
                    </CopyToClipboard>
                  </span>
                  {isCopied ? (
                    <span style={{ color: "white" }}>Copied</span>
                  ) : (
                    ""
                  )}
                </button>
              </div>
              <div >
                <br /> <br />
                <button type="reset" onClick={resetData}>
                  <span>
                    <RefreshIcon sx={{ fontSize: 30 }} />
                  </span>
                  {reset ? <span style={{ color: "white" }}>Reset</span> : null}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div data-aos="fade-up">
          <img
            src={BlockCalImg}
            alt="BlockCalImg"
            width={567}
            height={469}
            className="img"
            data-aos="fade-up"
          />
        </div>
      </section>
    </div>
  )
}

export default BlockCalculator;