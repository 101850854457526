import React, { useEffect,useLocation} from 'react'
import telegramBotImg from "../../Assests/telegramBotIMG.png";
import  blueLeftSvg from '../../Assests/blueLeftSvg.png'
import "./TelegramBot.css";
import { Helmet } from 'react-helmet';
import LazyLoad from 'react-lazyload';
import Aos from "aos";
import TGBotApplicationImg from "../../Assests/TGBot_ApplicationImg.svg"

export const SidesvgContainer = 'SidesvgContainer';
export const TGBotSolutioncontainer = 'TGBotSolutioncontainer';
function TelegramBot() {
  useEffect(() => {
    Aos.init({duration: 500});
    window.scrollTo(0, 0);
  }, []);
    return (
      <>
      <Helmet>
      <title>Telegram Bot - Blocsys</title>
      <meta
        name="description"
        content="Telegram is one of the most widely used chat apps, with over 62 million active users.
                Although it may not be as powerful as Facebook Messenger or Whatsapp, 89% of Telegram's active users are eager to send money using the app, 
                according to Global Web Index."
      />
      <meta name="keywords" content="Telegram Bot Solutions in India, Telegram Bot Solutions in Pune, Telegram Bot Development Company in India, chat bot, telegram bot development in India, bot development, telegram development,telegram bot app, Chatbots, AI chatbot, Chatbot for Website, AI Bot, Customisable Telegram Chatbot, Custom Telegram Bots, Chatbot Development in Pune, Telegram Marketing Bots, Bot Development Services, Telegram Business Bots, Telegram Bot for eCommerce, Telegram Bot for Customer Service, Telegram Bot Providers, Telegram Bot Experts, Secure Telegram Bots, Telegram Bot Customization, Telegram Bot Consultancy, Telegram Bot, Telegram Bot Platform, Telegram Custom Bot, Telegram Bot Development, Telegram Bot Services, bot Services, father bot, Telegram AI Bot, Best Telegram Bots, Telegram Bot Integration Services, Telegram Messaging Bots, Professional Telegram Bots, AI-powered Telegram Bots, Telegram Automation, Build Telegram Bot, Custom Telegram Bots, Telegram bot for community engagement, Telegram bots to increase community, TG Bots, Telegram bot service provider company, Ready telegram bot, ready telegram bot provider company, Ready telegram bot product, telegram mini apps, telegram bot like hamster and tapswap, telegram bot like hamster, telegram bot like tapswap, taptoearn, taptosawp, bot like hamster, developement bot like hamster, ready bot application, telegram clicker app, telegram bot APIs, clicker app" />
      <meta name="Telegram Bot description" content="blocsys Telegram Bot" />
      <link rel="icon" href="/favicon.ico" />

      <meta property="og:title" content="Telegram Bot - Blocsys" />
      <meta
        property="og:description"
        content="Telegram is one of the most widely used chat apps, with over 62 million active users.
                Although it may not be as powerful as Facebook Messenger or Whatsapp, 89% of Telegram's active users are eager to send money using the app, 
                according to Global Web Index."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://blocsys.com/telegram-bot" />
      <meta
        property="og:image"
        content="https://media.licdn.com/dms/image/C4D0BAQGuijH3NOSAVw/company-logo_200_200/0/1656508703897?e=2147483647&v=beta&t=UNKFrOWMEILFnHnPu0sXQCuJvcaDnIZWzNKI-3NkEuM"
      />
      <meta property="og:site_name" content="Blocsys" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://blocsys.com/telegram-bot" />
    </Helmet>
     <section className="TGBotsection" data-aos="fade-up">
        <div data-aos="fade-up">
        <div className="sprayContainer">
            <div className="sprayEffect"></div>
        </div>
        <h1>Telegram Bot</h1>
        <p>
          Telegram is one of the most widely used chat apps, with over 62 million active users. 
          Although it may not be as powerful as Facebook Messenger or Whatsapp, 89% of Telegram's active users are eager to send money using the app, according to Global Web Index. 
          This is significantly more than the 46%, 46%, and 58% respective usage rates of apps like Messenger, Snapchat, and WhatsApp. 
          It makes sense that Telegram chose to make its bot development API available to developers.
        </p>
        
        </div>
        <div data-aos="fade-up"> 
          <LazyLoad>
          <img
            src={telegramBotImg}
            className="BDimg"
            alt="DappMainIMG"
            style={{ height: "400px", width: "500px", marginTop:80}}
            quality={100}
            
          />
          </LazyLoad>
        </div>
        </section>
         <section>
          <div className="button-section" >
            <div className="button-container">
              <a
                href="https://calendly.com/blocsys"
                target="_blank"
                rel="noopener noreferrer"
                className="button"
                style={{ backgroundColor: "#375BD2",borderRadius: "50px",color: "#fff" }}
              >
                Schedule Meeting
              </a>
              <a href="/contact-us" className="button" style={{ backgroundColor: "#375BD2",borderRadius: "50px",color: "#fff" }}>
                Talk to our Experts
              </a>
             </div>
            </div>
        </section> 
        <section className="SectionTGBotApplication"   data-aos="fade-up">
        <div    data-aos="fade-up">
          <img
            src={TGBotApplicationImg}
            className="img"
            alt="DefiDappsDevelopment"
            style={{ height: "300px", width: "450px", marginTop: "60px"}}
            quality={100}
            data-aos="fade-up"
          />
        </div>
        <div    data-aos="fade-up">
          <h4   data-aos="fade-up">Telegram Bots Tailored for Web3 and Crypto Enthusiasts</h4>
          <p   data-aos="fade-up">
            Telegram bots offer numerous benefits and versatile usage to Web3 and Crypto users. 
            These bots can provide 24/7 customer support, streamline e-commerce transactions, and efficiently distribute content. 
            Bots enhance user engagement through automation and community management while being cost-effective and scalable.  
          </p>

          
        </div>
      </section>

      

      <section>
        <div className="TGBotcontainer">
          <div
            className="Solutions"
            data-aos="fade-up"
          >
            <h4 data-aos="fade-up">Key Features of Telegram Bot</h4>
           </div>
          <div className="TGBotSolutionMain">
            <div
              className="TGBotSolutioncontainer"
              data-aos="fade-up"
            >
              <div
                className={`${TGBotSolutioncontainer} ${SidesvgContainer}`}
          
              >
                <div className="svgWrapper">
                  <img src={blueLeftSvg} width={10} height={46} alt='blueLeftSvg' />
                </div>
                <div className="content">
                  <h5>Engagement</h5>
                  <p>
                    Our Telegram bots offer tap-to-earn, play-to-earn, and high user engagement. 
                    They provide 24/7 brand interaction, reducing CRM costs, and use personalized chatbots for powerful branding.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="TGBotSolutioncontainer"
              data-aos="fade-up"
            >
              <div
                className={`${TGBotSolutioncontainer} ${SidesvgContainer}`}
          
              >
                <div className="svgWrapper">
                  <img src={blueLeftSvg} width={10} height={46} alt='blueLeftSvg' />
                </div>
                <div className="content">
                  <h5>Reach</h5>
                  <p>
                  With a vast user base in the millions, Telegram boasts high MAUs and retention rates.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="TGBotSolutioncontainer"
              data-aos="fade-up"
            >
              <div
                className={`${TGBotSolutioncontainer} ${SidesvgContainer}`}
                
              >
                <div className="svgWrapper">
                  <img src={blueLeftSvg} width={10} height={46} alt='blueLeftSvg'/>
                </div>
                <div className="content">
                  <h5>Global</h5>
                  <p>
                  Its global reach provides a significant platform for bots.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="TGBotSolutioncontainer"
              data-aos="fade-up"
            >
              <div
                className={`${TGBotSolutioncontainer} ${SidesvgContainer}`}
             
              >
                <div className="svgWrapper">
                  <img src={blueLeftSvg} width={10} height={46} alt='blueLeftSvg' />
                </div>
                <div className="content">
                  <h5>Integration</h5>
                  <p>
                    Telegram bots also include three global commands to enhance user 
                    communication and can integrate with platforms like Twitter and YouTube.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="TGBotSolutioncontainer"
              data-aos="fade-up"
            >
              <div
                className={`${TGBotSolutioncontainer} ${SidesvgContainer}`}
                
              >
                <div className="svgWrapper">
                  <img src={blueLeftSvg} width={10} height={46} alt='blueLeftSvg'/>
                </div>
                <div className="content">
                  <h5>Interaction</h5>
                  <p>
                    The platform allows for the creation of various social services through bots, 
                    enabling users to interact with brands anytime, anywhere. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        </>
    )
}

export default TelegramBot;
