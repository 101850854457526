const TeamDetails = [
    {
      "id": 1,
      "name": "Pranay Pazare",
      "role": "Lead Blockchain Developer",
      "image": require("../../Assests/PranayIMG.png"),
      "linkedinURL" : "https://www.linkedin.com/in/pranay-pazare/"
    },
    {
      "id": 2,
      "name": "Rohit Naiknaware",
      "role": "Lead Blockchain Developer",
      "image": require("../../Assests/RohitIMG.png"),
      "linkedinURL": "https://www.linkedin.com/in/rohit-naiknaware-216006181/"
    },
    {
      "id": 3,
        "name": "Yukta Wagh",
        "role": "Business Development Executive",
        "image": require("../../Assests/YuktaIMG.png"),
        "linkedinURL": "https://www.linkedin.com/in/yukta-wagh-111b47235/"
      },
      {
        "id": 4,
        "name": "Ankush Fale",
        "role": "Business Development Executive",
        "image": require("../../Assests/AnkushIMG.jpg"),
        "linkedinURL": "https://www.linkedin.com/in/ankush-f-85541b125/"
      },
      {
        "id": 5,
        "name": "Shubham Yemul",
        "role": "Senior UI/UX designer",
        "image": require("../../Assests/ShubhamYImg.png"),
        "linkedinURL": "https://www.linkedin.com/in/shubham-yemul5/"
      },
      {
      "id": 6,
      "name": "Shubhash Magadam",
      "role": "Full-Stack Developer",
      "image": require("../../Assests/shubhamIMG.png"),
      "linkedinURL": "https://www.linkedin.com/in/Shubhash-Magadam-475791232/"
    },
    {
      "id": 7,
      "name": "Aishwarya Wale",
      "role": "Full-Stack Developer",
      "image": require("../../Assests/aishwaryaIMG.png"),
      "linkedinURL": "https://www.linkedin.com/in/aishwarya-wale-380504193/"
    },
    {
      "id": 8,
      "name": "Kushal Rathore",
      "role": "Full-Stack Developer",
      "image": require("../../Assests/Kaushal-IMG.jpg"),
      "linkedinURL": "https://www.linkedin.com/in/Kushaal-Rathore-b55317205/"
    },
    {
      "id": 9,
      "name": "Bhashan Dhivare",
      "role": "Blockchain Developer",
      "image": require("../../Assests/BhushanIMG.png"),
      "linkedinURL": "https://www.linkedin.com/in/Bhashna-Dhivare-45397176b/"
    },
    {
      "id": 10,
      "name": "Nishit Patil",
      "role": "Web3 Developer",
      "image": require("../../Assests/NishantIMG.png"),
      "linkedinURL": "https://www.linkedin.com/in/Prashant-patel/"
    },
    {
      "id": 11,
      "name": "Samarth Mahajan",
      "role": "Full Stack Developer",
      "image": require("../../Assests/Samarth.jpg"),
      "linkedinURL": "https://www.linkedin.com/in/samarth-mahajan07/"

    },
    {
      "id": 12,
      "name": "Vaibhav Ugale",
      "role": "Full Stack Developer",
      "image": require("../../Assests/vaibhavIMG.png"),
      "linkedinURL": "https://www.linkedin.com/in/vaibhavugale-959aa2217/"

    },
    
   

  ]

export  default TeamDetails