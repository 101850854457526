const BenefitData = [
    {
       "image": require("../../Assests/Technical Proficiency.svg").default,
       "Title": "Elevated Technical Proficiency",
       "Description": "Recruit leading  developers distinguished by their robust technical acumen in Blocsys development."
    },
    {
       "image": require("../../Assests/spacializedKnowledgeBase.svg").default,
       "Title": "Specialized Knowledge Base",
       "Description": "Build a skilled team of top-notch Blocsys Developers dedicated to delivering excellent and continuous service."
    },
    {
       "image": require("../../Assests/SeniorDevelopers.svg").default,
       "Title": "Experienced Senior Developers",
       "Description": "Engage the services of  Blocsys developers who stand out as best-in-class, boasting a minimum of three years' experience in the field."
    },
    {
       "image": require("../../Assests/ProjectDelivery.jpg"),
       "Title": "Punctual Project Delivery",
       "Description": "Secure the expertise of leading  Blocsys developers to ensure the timely completion and delivery of projects as per the scheduled timeline."
    },
    {
       "image": require("../../Assests/CommitmentToSuccess.svg").default,
       "Title": "Commitment to Success",
       "Description": "Enlist highly proficient  Blocsys developers with a steadfast commitment to consistently achieving successful outcomes."
    },
    {
       "image": require("../../Assests/QA.jpg"),
       "Title": "Quality Assurance",
       "Description": "Engage the services of top-tier  Blocsys Developers to uphold  quality, maintaining code integrity and adhering to industry best practices for error-free development."
    }

  ]
  export default BenefitData;