import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./Pages/Homepage/HomePage";
import AboutUs from "./Pages/About us/About-Us";
import Services from "./Pages/ServicesSection/Services";
import Solutions from "./Pages/Solutions/Solutions";
import Technologies from "./Pages/Web3-Technologies/Web3-Technologies";
import Blogs from "./Pages/Blogs/Blogs";
import ContactUs from "./Pages/Contact us/Contact-us";
import BlockchainDevelopment from "./Pages/ServicesSection/BlockChainDevelopment";
import Nft from "./Pages/ServicesSection/nft-marketplace";
import DappDefi from "./Pages/ServicesSection/Defi-Dapps-Development-Services";
import BlockChainTest from "./Pages/ServicesSection/BlockChainTest";
import Privacy from "./Pages/Policy/PrivacyPolicy";
import Cookies from "./Pages/Policy/CookiePolicy";
import Iot from "./Pages/Solutions/Iot";
import Healthcare from "./Pages/Solutions/Healthcare";
import RealWorldAsset from "./Pages/Solutions/RealWorldAsset";
import Socialfi from "./Pages/Solutions/Socialfi";
import Tools from "./Components/Tools/Block-Calculator";
import BlockCalculator from "./Components/Tools/Block-Calculator";
import "react-chatbot-kit/build/main.css";
import "./App.css";

import ThemedExample from "./Components/ChatBot-2/Bot2";
import BlockChainConsultant from "./Pages/ServicesSection/blockchain-consultant";
import MainNavigation from "./Components/main-navigation";
import Hiredevelopers from "./Pages/ServicesSection/Hire-web3-dapps-blockchain-developers";
import Footer from "./Components/Footer";
import Devopsconsulting from "./Pages/ServicesSection/Devops-consulting";
import StableCoin from "./Pages/ServicesSection/StableCoin";
import { Helmet } from "react-helmet";
import TelegramBot from "./Pages/Solutions/TelegramBot";



const handleContextMenu = (event) => {
  event.preventDefault(); // Prevent the default right-click behavior
};





function App() {

  const schemaOrg = {
    "@context": "http://schema.org",
    "@type": "Organization",
    name: "Blocsys",
    url: "https://www.blocsys.com/",
    logo:
      "https://media.licdn.com/dms/image/C4D0BAQGuijH3NOSAVw/company-logo_200_200/0/1656508703897?e=2147483647&v=beta&t=UNKFrOWMEILFnHnPu0sXQCuJvcaDnIZWzNKI-3NkEuM",
    description:
      "Pune-based Blockchain firm, Blocsys, leading in Defi, Dapps, NFT, and smart contracts. Offering end-to-end services with a skilled team.",
    founder: {
      "@type": "Person",
      name: "Shantikumar Chougule",
    },
  };


  return (
    <>
      <Helmet>
        <title>Blocsys - Web3 Solutions</title>
        <meta
          name="description"
          content="Blocsys, Pune's 2021 blockchain leader in Defi/Dapps, NFTs, and smart contracts. CEO Shantikumar Chougule heads a skilled team for top-tier services."
        />
        <link rel="canonical" href="https://www.blocsys.com/" />

        {/* Open Graph */}
        <meta property="og:url" content="https://www.blocsys.com/" />
        <meta property="og:title" content="Blocsys Web3-Solutions" />
        <meta
          property="og:description"
          content="Blocsys, Pune's 2021 blockchain leader in Defi/Dapps, NFTs, and smart contracts. CEO Shantikumar Chougule heads a skilled team for top-tier services."
        />
        <meta
          property="og:image"
          content="https://media.licdn.com/dms/image/C4D0BAQGuijH3NOSAVw/company-logo_200_200/0/1656508703897?e=2147483647&v=beta&t=UNKFrOWMEILFnHnPu0sXQCuJvcaDnIZWzNKI-3NkEuM"
        />
        <meta property="og:site_name" content="Blocsys Web3-Solutions" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:handle" content="@blocsys" />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow" />

        {/* Additional Link Tags */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="og:icon" href="/favicon.ico" />

        {/* JSON-LD for Schema.org */}
        <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
      </Helmet>
      <div onContextMenu={handleContextMenu}>
        {/* <BrowserRouter> */}
          {/* <MainNavigation /> */}
          {/* <Routes>
            <Route path="/" exact element={<HomePage />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/Services" element={<Services />}></Route>
            <Route path="/Solutions" element={<Solutions />}></Route>
            <Route path="/web3-technologies" element={<Technologies />}></Route>
            <Route path="/blogs" element={<Blogs />}></Route>
            <Route path="/Tools" element={<Tools />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route
              path="/hire-web3-dapps-blockchain-developers"
              element={<Hiredevelopers />}
            ></Route>
            <Route path="/stable-coin" element={<StableCoin />}></Route>

            <Route
              path="/blockchain-development"
              element={<BlockchainDevelopment />}
            ></Route>
            <Route path="/nft-marketplace" element={<Nft />}></Route>
            <Route
              path="/blockchain-consultant"
              element={<BlockChainConsultant />}
            ></Route>
            <Route
              path="/devops-consultant"
              element={<Devopsconsulting />}
            ></Route>
            <Route
              path="/defi-dapps-development-services"
              element={<DappDefi />}
            ></Route>
            <Route
              path="/blockchain-testing"
              element={<BlockChainTest />}
            ></Route>
            <Route path="/PrivacyPolicy" element={<Privacy />} />
            <Route path="/CookiePolicy" element={<Cookies />} />
            <Route path="/iot" element={<Iot />} />
            <Route path="/health-care" element={<Healthcare />} />
            <Route path="/asset-tokenization" element={<RealWorldAsset />} />
            <Route path="/socialfi" element={<Socialfi />} />
            <Route path="/telegram-bot" element={<TelegramBot />} />
            <Route
              path="/web3-block-calculator"
              element={<BlockCalculator />}
            />
            {/* <Route path="/ModalWallet" element={<ModalWallet />} /> */}
          {/* </Routes> */} 
          {/* <Footer /> */}
        {/* </BrowserRouter> */}

        <BrowserRouter>
        <Routes>
        <Route path="/" exact element={<BlockCalculator/>}></Route>
        </Routes>
        </BrowserRouter>
   
        {/*<ChatBot /> */}
        {/* <ThemedExample /> */}
      </div>
    </>
  );
}

export default App;
